.card-image-container {
  position: relative;
  width: 100%; /* Atur sesuai kebutuhan */
  height: 100%; /* Atur sesuai kebutuhan */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Warna dan transparansi border */
  border-radius: 10px; /* Radius sesuai desain */
  overflow: hidden;
  background-color: #222; /* Warna latar belakang */
  transition: box-shadow 0.3s ease-in-out;
}

/* Gambar background di lapisan paling belakang */
.card-image-background {
  position: absolute;
}

/* Gradient overlay di lapisan tengah */
.card-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    61.63% 65.99% at 3.02% 4.67%,
    #4f4f4f 0%,
    #272727 100%
  );
}

.card-image-text {
  position: relative;
  z-index: 1;
  color: #f28a23; /* Warna teks sesuai desain */
  text-align: left;
  padding: 20px;
  font-family: "Manolo", sans-serif;
}

.card-image-text h1 {
  font-size: 24px;
  margin: 0;
}

.card-image-text p {
  font-size: 20px;
  color: #fff;
}

.glowing-div {
  width: 200px;
  height: 200px;
  background-color: #17181d;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.card-image-container:hover {
  animation: glow 1.5s infinite alternate; /* Pulsating glow */
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px 2px rgba(255, 165, 0, 0.6);
  }
  to {
    box-shadow: 0 0 20px 5px rgba(255, 165, 0, 0.8);
  }
}
