.projects-container {
  text-align: center;
  /* padding-top: 35px; */
}

.section-title {
  color: white;
  margin-top: 0px;
}

.roshorambo-bg-image {
  bottom: 0;
  right: 10px;
  width: 20%;
  opacity: 0.5;
}

.dice-bg-image {
  bottom: 0;
  left: -30px;
  width: 120%;
  object-fit: cover;
  opacity: 0.3;
}

.roshorambo-web-bg-image {
  top: 0;
  right: 0;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.dragon-bot-bg-image {
  bottom: -50px;
  right: 0;
  height: 100%;
  /* object-fit: cover; */
  opacity: 0.3;
}

.glowing-div {
  width: 200px;
  height: 200px;
  background-color: #17181d;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.glowing-div:hover {
  animation: glow 1.5s infinite alternate; /* Pulsating glow */
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px 2px rgba(255, 165, 0, 0.6);
  }
  to {
    box-shadow: 0 0 20px 5px rgba(255, 165, 0, 0.8);
  }
}
