.tokens-container {
    text-align: center;
}

.eyes-bg-image {
    bottom: -75px;
    left: -50px;
    width: 75%;
    object-fit: cover;
    opacity: 0.3; 
}

.dragon-bg-image {
    top: -50px;
    right:-30px;
    width: 75%;
    object-fit: cover;
    opacity: 0.3; 
}