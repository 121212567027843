.roadmaps-container {
  text-align: center;
}

.ant-steps-icon .ant-steps-icon-dot {
  background-color: #FB7606 !important;
}
  
.ant-steps-item-tail::after {
  background-color: #585858 !important;
}

.ant-steps-item-title {
  color: #FB7606 !important;
  font-family: Manolo !important;
}

.ant-steps-item-description {
  color: white !important;
  font-family: Manolo;
  font-size: 12px;
}


.step-right.disable {
  color: #6E6E6E !important;
}

.description-right.disable {
  color: #6E6E6E !important;
}

.step-left.disable {
  color: #6E6E6E !important;
}

.description-left.disable {
  color: #6E6E6E !important;
}

.ant-steps-item-disabled .ant-steps-icon-dot {
  background-color: #6E6E6E !important;
}




  
  
  