.features-container {
  padding-top: 50px;
  color: white;
  text-align: center;
}

.features-inner-container {
  background: #313131;
  border-radius: 20px;
  transition: box-shadow 0.3s ease-in-out;
}

.features-inner-container:hover {
  animation: glow 1.5s infinite alternate; /* Pulsating glow */
}

.features-inner-container.with-bg-image {
  background-image: url("../../assets/images/feature-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-item-title {
  color: #fb7606;
  font-size: 32px;
  margin-bottom: 5px;
  text-align: left;
}

.feature-item-description {
  color: white;
  font-size: 20px;
  margin-bottom: 5px;
  text-align: left;
}

.features-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/feature-bg.png");
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: 1;
}
