.navbar-container {
    /* background: black; */
    padding: 10px 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.navbar-button-container{
    background-color: #161616;
    padding: 12px 36px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-button {
    padding: 6px 12px;
    color: white;
    cursor: pointer;
}

.social-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}


.nav-button:hover {
    background-color: #2A2A2A;
    border-radius: 12px;
}

.mobile-navbar-menu {
    position: absolute;
    top: 100%;
    right: 10px;
    display: flex;
    background-color: #161616;
    padding: 10px 0;
    width: 200px;
    z-index: 1000;
    border-top: 1px solid #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    gap: 10px
  }
