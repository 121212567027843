.faq-container {
    text-align: center;
}

.faq-collapse {
    /* border: none !important; */
    border: 2px solid rgba(255, 255, 255, 0.2)
}

.faq-collapse .ant-collapse-header {
 background-color: #222;
}


.faq-collapse .ant-collapse-item {
    border-bottom:  2px solid rgba(255, 255, 255, 0.2)
}

.faq-collapse .ant-collapse-header-text {
    font-family: Manolo !important;
    color: #FB7606 !important;
    font-size: 20px;
}

.faq-collapse .ant-collapse-content {
    /* background-color: transparent !important; */
 background-color: #222;

    border-top: none !important;

}

.faq-collapse .ant-collapse-content-box {
   padding: 8px !important;
}

.faq-collapse .ant-collapse-arrow {
    color: #FB7606 !important;
    font-size: 18px !important;
}

.faq-collapse .ant-collapse-content-box  {
    font-family: Manolo !important;
    color: white;
    font-size: 16px;
}

.faq-content-children p {
    margin: 0px !important;
}


  .ant-collapse > .ant-collapse-item:first-child .ant-collapse-header {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }