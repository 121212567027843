@font-face {
  font-family: 'Manolo';
  src: local('Manolo'), url('./assets/fonts/ManoloMono.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Manolo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* global style */

.clickable {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  user-select: none;
}

.clickable:active {
  transform: scale(0.95);
}

/* overide antd behavior */

.ant-col {
  font-family: 'Manolo'
}

