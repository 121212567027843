.landing-container {
  font-family: Manolo !important;
  color: white;
  position: relative;
  width: 100vw; /* Lebar penuh layar */
  height: 90vh; /* Tinggi penuh layar */
  overflow: hidden;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/landing-bg.png");
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: 1;
}

.hero-title {
  margin-bottom: 0px !important;
}

.hero-container {
  text-align: center;
}

.achivement-title {
  color: #fb7606;
  text-align: center;
  margin-bottom: 0px !important;
}

.achivement-description {
  font-size: 20px;
  text-align: center;
}

.landing-content {
  position: relative;
  z-index: 10 !important;
  opacity: 1 !important;
}

.achivement-container-title {
  text-align: center;
  padding-top: 30px;
}
