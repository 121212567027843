.card-image-container {
  position: relative;
  width: 100%; /* Atur sesuai kebutuhan */
  height: 100%; /* Atur sesuai kebutuhan */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Warna dan transparansi border */
  border-radius: 10px; /* Radius sesuai desain */
  overflow: hidden;
  background-color: #222; /* Warna latar belakang */
}

/* Gambar background di lapisan paling belakang */
.card-image-background {
  position: absolute;
}

/* Gradient overlay di lapisan tengah */
.card-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    61.63% 65.99% at 3.02% 4.67%,
    #4f4f4f 0%,
    #272727 100%
  );
}

.card-image-text {
  position: relative;
  z-index: 1;
  text-align: left;
  padding: 20px;
  font-family: "Manolo", sans-serif;
}

.card-image-text.text-bottom {
  position: absolute !important;
  bottom: 0 !important;
}

.token-name {
  color: white;
  font-size: 60px;
  margin: 0;
}

.token-description {
  color: #fb7606 !important;
  font-size: 2px;
  margin: 0;
}

.token-details {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5px;
}
